.resource {
    &.mt-12 {
        margin-top: 0;
    }
    div {
        h6 {
            margin-top: 24px;
            @media screen and (min-width: 1023px) {
                margin-top: 24px;
            }
            @media screen and (min-width: 640px) {
                margin-top: 58px;
            }
        }
    }
}