.parsed-html {
    &_health-welness {
        p {
            b {
                color: #f04123;
            }
        }
    }

    &_to-woman {
        p {
            b {
                color: #26245f;
            }
        }
    }

    &_mobility {
        h6 {
            margin-top: 45px;
            font-size: 30px;
        }
    }
}
