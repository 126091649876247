// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// @import components styles
@import '../components/quote-with-texts/styles/quote-with-texts.scss';
@import '../components/without-color-section/styles/without-color-section.scss';

// @import page styles if needed
@import '../pages/resources/styles/resource.scss';

// fonts Helvetica Neue Condensed
@layer base {
  body {
    background: rgb(255, 248, 248);
  }

  @font-face {
    font-family: 'Helvetica Neue Condensed Bold';
    font-weight: 400;
    src: url(../assets/fonts/Helvetica-Neue-Condensed-Bold.eot);
    src: url(../assets/fonts/Helvetica-Neue-Condensed-Bold.eot?#iefix) format('embedded-opentype'),
          url(../assets/fonts/Helvetica-Neue-Condensed-Bold.woff) format("woff"),
          url(../assets/fonts/Helvetica-Neue-Condensed-Bold.woff2) format('woff2'),
          url(../assets/fonts/Helvetica-Neue-Condensed-Bold.ttf) format('truetype');
  }
}


@layer components {
  .event-links h6 {
    @apply  text-base-color font-comp leading-none uppercase mb-5 ls:mb-7 lg:mb-2;
  }
  .event-links ul li p  {
    @apply my-3 lg:my-1.5 leading-normal;
  }
}